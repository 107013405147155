<template>
    <!-- 培训协议 -->
    <div class="jxMyAssessmentDetails">
        <van-nav-bar title="我的考核表详情"
            left-text=""
            left-arrow
            @click-left="onClickLeft"
            @click-right="onClickRight" 
        />
        <div class="overflow_div">
            <div class="problemBox">
                <div class="class" v-for="(item,index) in data" :key="index">
                    <div class="classTitle">
                        <span class="red">*</span>
                        <span class="classTitleName">职业素养</span>
                    </div>
                    <div class="problemList">
                        <div v-for="(ite,ind) in item.children" :key="ind">
                        <div class="problemTitle">{{(ind+1)}}.{{ite.indicatorname}}</div>
                            <van-row class="procho">
                                <van-col span="4" class="perlist">
                                <div class="tc"></div>
                                <div class="pername" v-for="(it,i) in ite.children" :key="i">{{it.empname}}</div>
                                </van-col>
                                <van-col span="20" class="choslist">
                                <div class="tc chostitle">
                                    <div
                                    v-for="(it,i) in select"
                                    :key="i"
                                    :style="'width:calc( 100% / '+select.length+')'"
                                    >{{it.selectname}}</div>
                                </div>
                                <van-radio-group
                                    v-model="it.selectindex"
                                    direction="horizontal"
                                    class="choser"
                                    v-for="(it,i) in ite.children"
                                    :key="i"
                                >
                                    <van-radio
                                    v-for="(it,i) in select"
                                    :key="i"
                                    :name="it.selectindex"
                                    :style="'width:calc( 100% / '+select.length+')'"
                                    >
                                    <template #icon="props">
                                        <div class="checked" v-if="props.checked"></div>
                                    </template>
                                    </van-radio>
                                </van-radio-group>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false, // 选人框是否显示
            choAss: {
                khplanname: ''
            },
            canAddPer: 0, // 可选人数
            hasAddPer: 0, // 已选人数
            isedit: true, // 是否允许评价
            data: [
                {
                    typename: '客户导向',
                    children: [
                        {
                            indicatorname: '【了解客户需求】主动收集信息',
                            children: [{
                                sqnum: 0,
                                typename: '客户导向',
                                indicatorname: '【了解客户需求】主动收集信息',
                                selectindex: null,
                                autoid: 0,
                                empid: 0,
                                empname: '张三',
                                isedit: true
                            }]
                        }
                    ]
                }
            ],
            select: [
                {
                    selectindex: 1,
                    selectname: '从不'
                },
                {
                    selectindex: 2,
                    selectname: '很少'
                },
                {
                    selectindex: 3,
                    selectname: '偶尔'
                },
                {
                    selectindex: 4,
                    selectname: '经常'
                },
                {
                    selectindex: 5,
                    selectname: '总是'
                }
            ]
        }
    },
    created() {
        // 接收传入的评价方案信息
        const v = sessionStorage.getItem('jxWhenMyAssesListDetail')
        if (v) {
            this.choAss = JSON.parse(v)
            this.iniPer()
        } else {
            // 没有内容则回到上一页重新选择
            this.$router.push('/jxMyAssessment')
        }
    },
    methods: {
        // 回退
        onClickLeft() {
            this.$router.push('/jxMyAssessment')
        },
        // 处理人员数据
        iniPer() {
            this.canAddPer = 0
            this.hasAddPer = 0
            this.choAss.children.forEach(e => {
                e.selected = false
                if (e.checked) {
                    this.hasAddPer++
                } else {
                    this.canAddPer++
                }
            })
        },
        // 删除人员
        del(e) {
            e.checked = false
            this.iniPer();
        },
        // 显示选人框
        showBox() {
            this.show = true
        },
        // 选中人员
        select(e) {
            e.selected = !e.selected
            this.$forceUpdate();
        },
        // 选择完人员后
        done() {
            this.choAss.children.forEach(e => {
                if (e.selected) {
                    e.checked = true
                }
            })
            this.iniPer()
        },
        //  提交
        confirm() {}
    }
}
</script>

<style lang="less" scoped>
.jxMyAssessmentDetails{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: #eceaea;
    .van-nav-bar {
        background: #2b8df0;
        /deep/ .van-nav-bar__left {
            .van-icon {
                color: #fff;
            }
        }
        /deep/.van-nav-bar__title {
            color: #fff;
            font-size: 36px;
            font-family: Source Han Sans CN;
        }
        ::v-deep .van-nav-bar__right {
            .van-nav-bar__text {
                color: #fff;
                font-family: Source Han Sans CN;
            }
        }
    }
    .overflow_div {
        overflow: auto;
        height: calc(100% - 210px);
        .person {
            background: #fff;
            padding: 30px;
            margin-bottom: 20px;
            .personlist {
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                flex-direction: column;
                height: 180px;
                .viewZp {
                    width: 106px;
                    height: 106px;
                    position: relative;
                    font-size: 106px;
                    img,.svg-icon {
                        width: 100%;
                        height: 100%;
                    }
                    .del {
                        width: 36px;
                        height: 36px;
                        position: absolute;
                        top: 0;
                        right: -12px;
                    }
                }
                .personname {
                    font-size: 40px;
                }
            }
        }
        .problemBox {
            background: #fff;
            .msg {
                font-size: 32px;
                color: #333;
                border-bottom: 1px solid #ccc;
                padding: 30px 20px;
            }
            .btnbox {
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0;
                .plbtn {
                    width: 290px;
                    height: 70px;
                    margin: 0 auto;
                    font-size: 36px;
                }
            }
            .class {
                padding: 42px 10px;
                .classTitle {
                    font-size: 32px;
                    color: #000;
                    font-weight: bold;
                    .red {
                    color: #f53838;
                    }
                }
                .problemList {
                    border: 1px solid #ccc;
                    border-top: 15px solid #2b8df0;
                    border-radius: 10px;
                    margin-top: 24px;
                    padding: 0 20px 20px;
                    font-size: 28px;
                    .problemTitle {
                        color: #2b8df0;
                        margin-top: 30px;
                    }
                    .procho {
                        .perlist {
                            .tc {
                                height: 50px;
                            }
                            .pername {
                                height: 50px;
                                max-width: 120px;
                            }
                        }
                        .choslist {
                            .tc {
                                height: 50px;
                            }
                            .chostitle {
                                display: -webkit-box;
                                display: -webkit-flex;
                                display: flex;
                                -webkit-box-align: center;
                                -webkit-align-items: center;
                                align-items: center;
                                justify-content: space-around;
                                div {
                                    text-align: center;
                                }
                            }
                            .choser {
                                height: 50px;
                                display: -webkit-box;
                                display: -webkit-flex;
                                display: flex;
                                -webkit-box-align: center;
                                -webkit-align-items: center;
                                align-items: center;
                                justify-content: space-around;
                                .van-radio {
                                    margin: 0;
                                    display: -webkit-box;
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-box-align: center;
                                    -webkit-align-items: center;
                                    align-items: center;
                                    justify-content: space-around;
                                    .checked {
                                        width: 41px;
                                        height: 41px;
                                        background: #ffffff;
                                        border: 11px solid #2b8df0;
                                        border-radius: 50%;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>